
import Vue from "vue";

import { mdiPlus, mdiMagnify } from "@mdi/js";
import http from "@/http";
import { Document, DocumentResponse } from "@/interfaces";

export interface Query {
  keyword: string;
  page: number;
}

export interface DataType {
  documents: Document[];
  query: Query;
  page: number;
  hasPrev: boolean;
  hasNext: boolean;
}

import MyPager from "@/components/MyPager.vue";

export default Vue.extend({
  components: {
    MyPager,
  },
  data(): DataType {
    return {
      documents: [],
      query: {
        keyword: "",
        page: 0,
      },
      page: 1,
      hasPrev: false,
      hasNext: false,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "ドキュメント",
          disabled: true,
          to: "/documents",
        },
      ];
    },
    icon() {
      return {
        mdiPlus,
        mdiMagnify,
      };
    },
  },
  watch: {
    async $route() {
      this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async submit() {
      await this.$router
        .replace({
          path: "/documents",
          query: {
            keyword: this.query.keyword,
            page: this.query.page.toString(),
          },
        })
        .catch(() => {});
    },
    handleQuery() {
      let keyword = "";
      let page = 1;

      if (typeof this.$route.query.keyword === "string") {
        keyword = this.$route.query.keyword;
      }

      if (typeof this.$route.query.page === "string") {
        page = +this.$route.query.page;
      }

      this.query = {
        keyword,
        page,
      };
    },
    async fetchData() {
      this.handleQuery();

      const params = {
        ...this.query,
      };

      const { data } = await http.get<DocumentResponse>("documents", {
        params,
      });

      this.documents = data.data;

      this.hasNext = !!data.next_page_url;
      this.hasPrev = !!data.prev_page_url;
      this.page = +data.current_page;
    },
    async next() {
      const page = +this.query.page;

      this.query.page = page + 1;

      await this.submit();
    },
    async prev() {
      const page = +this.query.page;

      this.query.page = page - 1;

      await this.submit();
    },
  },
});
